/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_CUSTOMER_FEEDS_URL } from "assets/constants";

import LMButton from "components/lm-button/LMButton";
import LMDropdown from "components/lm-dropdown/LMDropdown";
import Table from "components/table/Table";

// eslint-disable-next-line max-len
import updateTypeSpecsWithYieldValueProperties from "pages/feed-plan-calculation/utilities/updateTypeSpecsWithYieldValueProperties";
import LegendList from "pages/feed-plan-calculation/parts/LegendList";
import { lowerFirstLetter } from "pages/feed-plan-calculation/parts/FeedPlanOverview";

import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { CustomerFeedsContext } from "pages/customer-feeds/context/CustomerFeedsProvider";
import { setCurrentFeedPlan } from "pages/choose-customer/context/customerActions";

import IFeedPlanDropdownOptionsState from "interfaces/IFeedPlanDropdownOptionsState";
import ICustomerFeedPlan from "interfaces/ICustomerFeedPlan";
import IControlParameterResults from "interfaces/IControlParameterResults";
import IFeedDiet from "interfaces/IFeedDiet";
import IControlParameterSettingsRequest from "interfaces/IControlParameterSettingsRequest";
import ICustomerFeedsState from "interfaces/ICustomerFeedsState";
import ICustomerFeed from "interfaces/ICustomerFeed";

import typeSpecsOfFeedDietData from "pages/feed-plan-calculation/json/typeSpecsOfFeedDietData.json";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useFeedDietOptimization from "hooks/useFeedDietOptimization";
import useFeedHandler from "hooks/useFeedHandler";
import useToggle from "hooks/useToggle";
import useConfirm from "hooks/useConfirm";
import useDebounce from "hooks/useDebounce";

interface TablesWrapperProps {
  emptyData?: true;
  feedDietData?: IFeedDiet[];
  controlParameterData?: IControlParameterResults[];
  feedPlan: ICustomerFeedPlan;
  tableDropdownState: IFeedPlanDropdownOptionsState[] | undefined;
  updateTableDropdownState: (
    tableDropdownState: IFeedPlanDropdownOptionsState[] | undefined
  ) => void;
  setUnsavedFeedDietItems: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedFeedDietItems: boolean;
  enableEdit: boolean;
}

const TablesWrapper = ({
  emptyData,
  feedDietData,
  controlParameterData,
  feedPlan,
  tableDropdownState,
  updateTableDropdownState,
  setUnsavedFeedDietItems,
  unsavedFeedDietItems,
  enableEdit,
}: TablesWrapperProps) => {
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "controlParameters",
  });

  const {
    customerDispatch,
    customerState: { currentFeedPlan, currentCustomer },
  } = useContext(CustomerContext);

  const { customerFeedsState } = useContext(CustomerFeedsContext);

  const { getFeeds: getCustomerFeeds } = useFeedHandler(customerFeedsState);

  const { isConfirmed } = useConfirm();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { updateFeedDietItemInCurrentFeedPlan, getDefaultControlParameterSettings } =
    useSaveFeedRequest();
  const [defaultParameters, setDefaultParameters] = useState<IControlParameterSettingsRequest[]>(
    []
  );
  const {
    checkEnergyAndMetabolizedProteinValues,
    optimizeFeedDietItems,
    convertObjectToJsonArray,
    convertJsonArrayToObject,
  } = useFeedDietOptimization();
  const [optimizedCount, setOptimizedCount] = useState(0);
  const [isOptimizing, toggleIsOptimizing] = useToggle(false);
  const [updatedRows, setUpdatedRows] = useState<IFeedDiet[]>([]);

  useEffect(() => {
    const getDefaultParameters = async () => {
      const res = await getDefaultControlParameterSettings();
      setDefaultParameters(res);
    };
    getDefaultParameters();
  }, []);

  useEffect(() => {
    let count = 0;
    feedDietData?.forEach((item) => {
      if (item.isOptimized) {
        count += 1;
      }
    });

    if (optimizedCount !== count) {
      setOptimizedCount(count);
    }
  }, [feedDietData]);

  const handleOnClick = useCallback(() => {
    push(`${url}/add-remove`);
  }, [push, url]);

  const handleNutrientOptionChange = useCallback(
    (position: number) => {
      // updating an object instead of a Map
      const updatedCheckedState = tableDropdownState?.map((item, index) => {
        if (index === position) {
          item.visible = !item.visible;
        }
        return item;
      });
      updateTableDropdownState(updatedCheckedState);
    },
    [updateTableDropdownState, tableDropdownState]
  );

  const isDryCowOptValuesZero = (updatedValues: any): boolean => {
    if (updatedValues["closeUp3-0w"] === 0 && updatedValues["farOff8-3w"] === 0) {
      return true;
    }
    return false;
  };

  const renderControlParameterOptions = (): JSX.Element[] => {
    if (tableDropdownState && tableDropdownState.length) {
      return tableDropdownState.map((option, index) => (
        <li key={option.name} className="dropdown-item">
          <label className="lm__checkbox lm__tick dropdown-checkbox" htmlFor={option.name}>
            <input
              id={option.name}
              type="checkbox"
              value={option.value || ""}
              name="nutrientOptions"
              checked={tableDropdownState ? tableDropdownState[index].visible : false}
              onChange={() => handleNutrientOptionChange(index)}
              disabled={option.disable}
            />
            <label />
            <span className="margin-left-5">{tWithKeyPrefix(lowerFirstLetter(option.name))}</span>
          </label>
        </li>
      ));
    }
    return defaultParameters.map((option, index) => (
      <li key={option.name} className="dropdown-item">
        <label className="lm__checkbox lm__tick dropdown-checkbox" htmlFor={option.name}>
          <input
            id={option.name}
            type="checkbox"
            value={option.value || ""}
            name="nutrientOptions"
            checked={false}
            disabled
            onChange={() => handleNutrientOptionChange(index)}
          />
          <label />
          <span className="margin-left-5">{tWithKeyPrefix(lowerFirstLetter(option.name))}</span>
        </label>
      </li>
    ));
  };

  const updateFeedPlanAfterOptimization = async (
    currentRows: IFeedDiet[],
    optimizedFeedPlan: ICustomerFeedPlan) => {
    
    const shouldUpdateRow = (
      animalTypeName: string,
      item: any,
      updatedRowArray: any,
      updatedRowIndex: number
    ): boolean => animalTypeName !== "milkCow" || item[0] === updatedRowArray[updatedRowIndex][0];

    const updateRowValue = (updatedRowArray: any, updatedRowIndex: number, value: any) => {
      updatedRowArray[updatedRowIndex][1] = value;
    };
  
    const updateRowInUpdatedRows = (rows: IFeedDiet[], oldRow: IFeedDiet, newRow: IFeedDiet) => {
      rows[rows.indexOf(oldRow)] = newRow;
    };
  
    const handleDryCowError = (animalTypeName: string, updatedRow: any) => {
      if (animalTypeName === "dryCow" && isDryCowOptValuesZero(updatedRow)) {
        isConfirmed(t("optimizationValueError"), "alert");
      }
    };
    const YIELD_INDEX_START: number = 4;
  
    currentRows.forEach((updatedRow: IFeedDiet) => {
      optimizedFeedPlan.feedDietItems.forEach((item: any) => {
        if (updatedRow.customerFeedId === item.customerFeedId) {
          const updatedRowArray = convertObjectToJsonArray(updatedRow);
          const itemRowArray = convertObjectToJsonArray(item.yieldLevelValues);
  
          itemRowArray.forEach((item1: any, index: number) => {
            const updatedRowIndex: number = index + YIELD_INDEX_START;
  
            if (shouldUpdateRow(
              optimizedFeedPlan.animalType.name,
              item1,
              updatedRowArray,
              updatedRowIndex)) {
              updateRowValue(updatedRowArray, updatedRowIndex, item1[1]);
            }
          });
  
          const updatedRowNew: IFeedDiet = convertJsonArrayToObject(updatedRowArray) as IFeedDiet;
          updateRowInUpdatedRows(currentRows, updatedRow, updatedRowNew);
  
          handleDryCowError(optimizedFeedPlan.animalType.name, updatedRowNew);
        }
      });
    });

    const updatedFeedPlanAfterOptimization = await updateFeedDietItemInCurrentFeedPlan(
      optimizedFeedPlan,
      currentRows
    );
    return updatedFeedPlanAfterOptimization;
  }

  const handleOnUpdatedFeedDietItems = useCallback(
    async () => {

    if (!updatedRows || updatedRows.length === 0 || !currentFeedPlan) {
      return;
    }

    toggleIsOptimizing(true);
    const updatedFeedPlan = await updateFeedDietItemInCurrentFeedPlan(
      currentFeedPlan,
      updatedRows
    );

    let optimizeCount: number = 0;

    if (updatedFeedPlan) {
      updatedFeedPlan.feedDietItems.forEach((item) => {
        if (item.isOptimized === true) {
          optimizeCount += 1;
        }
      });
    }

    if (optimizeCount > 0) {
      const customerFeedData: ICustomerFeedsState = await getCustomerFeeds(
        `${BASE_CUSTOMER_FEEDS_URL}?customerId=${currentCustomer?.customerId}`
      );
      const customerFeeds: ICustomerFeed[] = customerFeedData.feeds;

      const performOptimizationDataCheck: boolean =
        checkEnergyAndMetabolizedProteinValues(currentFeedPlan);

      if (performOptimizationDataCheck) {
        isConfirmed(t("feedDietDataOptimizationError"), "alert");
      } else {

      const optimizedFeedPlan = optimizeFeedDietItems(updatedFeedPlan, customerFeeds);
        if (optimizedFeedPlan) {
          const updatedFeedPlanAfterOptimization = await updateFeedPlanAfterOptimization(
            updatedRows,
            optimizedFeedPlan);
          customerDispatch(setCurrentFeedPlan(updatedFeedPlanAfterOptimization));
        } else {
          customerDispatch(setCurrentFeedPlan(optimizedFeedPlan));
        }
      }
    } else {
      customerDispatch(setCurrentFeedPlan(updatedFeedPlan));
    }

    if (!unsavedFeedDietItems) {
      setUnsavedFeedDietItems(true);
    }
    toggleIsOptimizing(false);
  }, [currentFeedPlan, updatedRows, currentCustomer]);

  const debounceUpdateFeedDietItems = useDebounce(handleOnUpdatedFeedDietItems, 1500);
  const handleRowEdit = (editedRow: IFeedDiet) => {
    const existingIndex = updatedRows.findIndex(row => row.feedName === editedRow.feedName);

    if (existingIndex !== -1) {
      setUpdatedRows((prevRows) => {
        const updatedRowsCopy = [...prevRows];
        updatedRowsCopy[existingIndex] = editedRow;
        return updatedRowsCopy;
      });
    } else {
      setUpdatedRows((prevRows) => [...prevRows, editedRow]);
    }

    debounceUpdateFeedDietItems();
  };

  const handleTableHeaderClick = useCallback(
    async (headColumn: string) => {
      const animalTypeName = currentFeedPlan?.animalType.name;

      if (animalTypeName === "milkCow") {
        headColumn = headColumn.replace(".00", "");
      }

      const yieldLevel = currentFeedPlan?.yieldLevels.find(
        (_yieldLevel) => _yieldLevel.description === headColumn
      );

      if (yieldLevel && yieldLevel.level) {
        const answer = await isConfirmed(t("feedDietDataYieldRedirect"));
        if (answer) {
          return push(`/feed-mix/create-feed-mix/${yieldLevel.level}`);
        }
      }
    },
    [currentFeedPlan]
  );

  return (
    <div className={`tables-wrapper margin-right-15 ${!enableEdit ? "read-only" : "open"}`}>
      <div className="table-header">
        <h1>{t("feedDiet")}</h1>
        <LMButton
          text={t("addRemoveFeeds")}
          type="button"
          click={handleOnClick}
          disabled={!enableEdit}
        />
      </div>

      <div className={`feed-diet-table ${isOptimizing ? "disabled" : ""}`}>
        <Table
          data={feedDietData!}
          typeSpecs={updateTypeSpecsWithYieldValueProperties(
            feedDietData!,
            typeSpecsOfFeedDietData
          )}
          keyPrefix="feedDietData"
          emptyTableData={emptyData}
          onUpdatedRow={handleRowEdit}
          readOnly={!enableEdit || isOptimizing}
          optimizedFeedDiets={optimizedCount}
          unsavedItems={unsavedFeedDietItems}
          onYieldLevelHeadClick={handleTableHeaderClick}
          feedPlan={feedPlan}
        />
      </div>
      <div className="table-header">
        <h1>{t("controlParametersTitle")}</h1>
        <LMDropdown multiple disabled={!enableEdit} placeholder={t("showControlParameters")}>
          {renderControlParameterOptions()}
        </LMDropdown>
      </div>
      <LegendList />
      <div className="control-parameter-table">
        <Table
          data={controlParameterData!}
          typeSpecs={typeSpecsOfFeedDietData}
          emptyTableData={emptyData}
          keyPrefix="controlParameterData"
          readOnly={!enableEdit}
          feedPlan={feedPlan}
        />
      </div>
    </div>
  );
};

export default TablesWrapper;
