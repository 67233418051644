/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import useFetch from "hooks/useFetch";
import useInterpolation from "hooks/useInterpolation";
import useConfirm from "hooks/useConfirm";
import { useTranslation } from "react-i18next";

import IPostOptions from "interfaces/IPostOptions";
import IPutOptions from "interfaces/IPutOptions";
import IDeleteOptions from "interfaces/IDeleteOptions";
import ICustomerFeedPlanRequest from "interfaces/ICustomerFeedPlanRequest";
import ICustomerFeed from "interfaces/ICustomerFeed";
import IFeedMix from "interfaces/IFeedMix";
import IFeedMixUpdateRequest from "interfaces/IFeedMixUpdateRequest";
import IFeedMixCreateRequest from "interfaces/IFeedMixCreateRequest";
import IFeedMixPrint from "interfaces/IFeedMixPrint";
import IFeedPlanPrint from "interfaces/IFeedPlanPrint";

import IControlParameter from "interfaces/IControlParameter";
import IFeedPlanDropdownOptionsState from "interfaces/IFeedPlanDropdownOptionsState";
import IControlParameterSettingsRequest from "interfaces/IControlParameterSettingsRequest";
import IFeedDiet from "interfaces/IFeedDiet";

import ICustomerFeedPlan, { IFeedDietItem, IYieldLevelValues } from "interfaces/ICustomerFeedPlan";

import {
  DEFAULT_CONTROL_PARAMETER_SETTINGS_URL,
  BASE_FEED_PLAN_URL,
  BASE_FEED_MIX_URL,
  BASE_ANIMAL_TYPES_URL,
  BASE_YIELD_LEVEL_URL,
} from "assets/constants";
import { useCallback } from "react";
import i18n from "../i18n";

function lowerFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

function useSaveFeedRequest() {
  const { get, put, post, delete: deleteRequest } = useFetch();
  const { getYieldInterpolationValues } = useInterpolation();
  const { t } = useTranslation();
  const { isConfirmed } = useConfirm();

  const createPdfFile = async (url: string, requestOptions: IPostOptions, pdfName: string) => {
    let ok;

    const file = await post(url, requestOptions)
      .then(async (res) => {
        ok = res.ok;

        if (res === 400) {
          await isConfirmed(t("printInactiveError"), "alert");
          return;
        }
        if (!ok) {
          await isConfirmed(t("printError"), "alert");
          return;
        }

        return res.blob();
      })
      .then((blob) => {
        if (blob) {
          return URL.createObjectURL(blob);
        }
        return t("feedMixPrintError");
      });

    if (ok) {
      const link = document.createElement("a");
      link.href = file;
      link.download = `${pdfName}.pdf`;

      return { link, file };
    }
  };

  const saveFeedPlanToDatabase = async (feedRequest: ICustomerFeedPlanRequest) => {
    const requestOptions: IPostOptions = {
      method: "POST",
      body: JSON.stringify(feedRequest),
    };
    const result = await post(BASE_FEED_PLAN_URL, requestOptions);
    if (result === 409) {
      await isConfirmed(`${t("dublicateFeedPlanError")} ${feedRequest.name}`, "alert");
      return;
    }
    return result.json();
  };

  const editFeedPlanInDatabase = async (
    feedPlanId: string,
    feedRequest: ICustomerFeedPlanRequest
  ) => {
    const requestOptions: IPutOptions = {
      method: "PUT",
      body: JSON.stringify(feedRequest),
    };
    await put(`${BASE_FEED_PLAN_URL}/${feedPlanId}/settings`, requestOptions);
  };

  const editFeedsInCurrentFeedPlan = async (
    feedPlanId: string,
    feedDietItems: IFeedDietItem[],
    persist: boolean = true
  ) => {
    const body = { feedDietItems };

    const requestOptions: IPutOptions = {
      method: "PUT",
      body: JSON.stringify(body),
    };

    let result = await put(
      `${BASE_FEED_PLAN_URL}/${feedPlanId}/feeds?persist=${persist}`,
      requestOptions
    );

    result = await result.json();

    return result;
  };

  const deleteFeedPlanFromDatabase = async (feedPlanId: number) => {
    const url = `${BASE_FEED_PLAN_URL}/${feedPlanId}`;
    const requestOptions: IDeleteOptions = {
      method: "DELETE",
    };
    return deleteRequest(url, requestOptions);
  };

  const saveEditedFeedsToDB = async (feedPlan: ICustomerFeedPlan) => {
    let feedDietItemsCopy = feedPlan?.feedDietItems;
    const customerFeedsToAddToFeedDietItems: ICustomerFeed[] = [];
    const customerFeedsToRemoveFromFeedDietItems: number[] = [];

    if (!feedPlan) {
      return;
    }

    if (!feedDietItemsCopy) {
      feedDietItemsCopy = [];
    }

    const answer = await isConfirmed(t("unsavedChanges"), "reset", () => {});

    if (!answer) {
      return null;
    }

    // Find the feeds to add to the currentFeedPlan
    feedPlan.feeds.forEach((feed) => {
      if (!feedPlan.feedDietItems.some((feedDietItem) => feedDietItem.customerFeedId === feed.id)) {
        customerFeedsToAddToFeedDietItems.push(feed);
      }
    });

    // Find the feeds to remove from the currentFeedPlan
    if (feedPlan.feeds.length === 0) {
      feedPlan.feedDietItems.forEach((feedDietItem) =>
        customerFeedsToRemoveFromFeedDietItems.push(feedDietItem.customerFeedId)
      );
    } else {
      feedPlan.feedDietItems.forEach((feedDietItem) => {
        if (!feedPlan.feeds.some((feed) => feed.id === feedDietItem.customerFeedId)) {
          customerFeedsToRemoveFromFeedDietItems.push(feedDietItem.customerFeedId);
        }
      });
    }

    // Remove feeds from the currentFeedPlan
    if (customerFeedsToRemoveFromFeedDietItems.length) {
      feedDietItemsCopy = feedDietItemsCopy.filter(
        (feedDietItem) =>
          !customerFeedsToRemoveFromFeedDietItems.includes(feedDietItem.customerFeedId)
      );
    }

    // Add feeds to the currentFeedPlan
    customerFeedsToAddToFeedDietItems.forEach((feed) => {
      const newFeedDietItem: IFeedDietItem = {} as IFeedDietItem;
      newFeedDietItem.isOptimized = false;
      newFeedDietItem.customerFeedId = feed.id!;
      newFeedDietItem.yieldLevelValues = {} as IYieldLevelValues;
      newFeedDietItem.sortIndex = feed.sortIndex;

      const yieldValues = getYieldInterpolationValues(
        feedPlan.yieldLevelStart,
        feedPlan.yieldLevelStep,
        feedPlan.yieldLevelStop
      );

      yieldValues.forEach((value) => {
        newFeedDietItem.yieldLevelValues[value.replace(/#/g, "")] = 0;
      });

      feedDietItemsCopy?.push(newFeedDietItem);
    });

    /**
     * Sort feedDietItems based on sortIndex from feedPlan.feeds
     */

    feedDietItemsCopy = feedDietItemsCopy.map((feedDietItem) => {
      const matchingFeed = feedPlan.feeds.find((feed) => feed.id === feedDietItem.customerFeedId);
      feedDietItem.sortIndex = matchingFeed ? matchingFeed.sortIndex : feedDietItem.sortIndex;
      return feedDietItem;
    });

    // Save the new feedDietItems
    return editFeedsInCurrentFeedPlan(String(feedPlan.id), feedDietItemsCopy);
  };

  const downloadFeedPlanPDF = async (
    feedPlanId: number,
    settings: IFeedPlanPrint,
    feedPlanName: string
  ) => {
    const { language } = i18n;

    let parsedLang: string;
    if (language.includes("sv")) {
      parsedLang = "sv";
    } else {
      parsedLang = "en";
    }

    const url = `${BASE_FEED_PLAN_URL}/${feedPlanId}/pdf?culture=${parsedLang}`;
    const requestOptions: IPostOptions = {
      method: "POST",
      body: JSON.stringify(settings),
    };

    return createPdfFile(url, requestOptions, feedPlanName);
  };

  const copyFeedPlanToDatabase = async (feedPlanId: number, newFeedPlanName: string) => {
    const url = `${BASE_FEED_PLAN_URL}/${feedPlanId}/copy`;
    const requestOptions: IPostOptions = {
      method: "POST",
      body: JSON.stringify({ newName: newFeedPlanName }),
    };

    const result = await post(url, requestOptions);
    const { id } = await result.json();

    return { result, id };
  };

  const getYieldLevelsFromDatabase = async () => {
    const url = `${BASE_YIELD_LEVEL_URL}`;
    const result = await get(url);
    return result.yieldLevels;
  };

  const getAnimalTypesFromDatabase = async () => {
    const url = `${BASE_ANIMAL_TYPES_URL}`;
    const result = await get(url);
    return result;
  };

  const checkControlParameterConditionsForTable = (
    defaultControlOptions: IControlParameterSettingsRequest[],
    feedPlanDropdownOption: IControlParameter,
    index: number
  ) => {
    let setDisableForCheckbox: boolean = false;
    if (defaultControlOptions[index].isDefaultVisibleInTable === true) {
      setDisableForCheckbox = defaultControlOptions[index].isDefaultVisibleInTable;
    } else if (feedPlanDropdownOption.isVisibleInTable === false) {
      setDisableForCheckbox = feedPlanDropdownOption.isVisibleInTable;
    } else {
      setDisableForCheckbox = !feedPlanDropdownOption.isVisibleInTable;
    }
    return setDisableForCheckbox;
  };

  const checkControlParameterConditionsForGraph = (
    defaultControlOptions: IControlParameterSettingsRequest[],
    feedPlanDropdownOption: IControlParameter,
    index: number
  ) => {
    let setDisableForCheckbox: boolean = false;
    if (defaultControlOptions[index].isDefaultVisibleInGraph === true) {
      setDisableForCheckbox = defaultControlOptions[index].isDefaultVisibleInGraph;
    } else if (feedPlanDropdownOption.isVisibleInGraph === false) {
      setDisableForCheckbox = feedPlanDropdownOption.isVisibleInGraph;
    } else if (feedPlanDropdownOption.isVisibleInGraph === true) {
      setDisableForCheckbox = !feedPlanDropdownOption.isVisibleInGraph;
    } else {
      setDisableForCheckbox = feedPlanDropdownOption.isVisibleInGraph;
    }
    return setDisableForCheckbox;
  };

  const getFeedPlanVisibleInTableOptions = (
    feedPlan: ICustomerFeedPlan,
    defaultControlOptions: IControlParameterSettingsRequest[]
  ) => {
    const feedPlanVisibleInTableOptions: IFeedPlanDropdownOptionsState[] =
      feedPlan.controlParameters.map((option: IControlParameter, index: number) => ({
        name: lowerFirstLetter(option.name),
        value: option.name,
        visible: option.isVisibleInTable,
        disable: checkControlParameterConditionsForTable(defaultControlOptions, option, index),
      }));
    return feedPlanVisibleInTableOptions;
  };

  const getFeedPlanVisibleInGraphOptions = (
    feedPlan: ICustomerFeedPlan,
    defaultControlOptions: IControlParameterSettingsRequest[]
  ) => {
    const feedPlanVisibleInGraphOptions: IFeedPlanDropdownOptionsState[] =
      feedPlan.controlParameters.map((option: IControlParameter, index: number) => ({
        name: lowerFirstLetter(option.name),
        value: option.name,
        visible: option.isVisibleInGraph,
        disable: checkControlParameterConditionsForGraph(defaultControlOptions, option, index),
      }));
    return feedPlanVisibleInGraphOptions;
  };

  const getDefaultControlParameterSettings = async () => {
    const result = await get(DEFAULT_CONTROL_PARAMETER_SETTINGS_URL);
    return result.defaultControlParameterSettings;
  };

  const changeControlSettings = (
    defaultControlParameterSettings: IControlParameterSettingsRequest[],
    currentFeedPlan: ICustomerFeedPlan
  ) => {
    const updatedControlParameterSettings:
      IControlParameterSettingsRequest[] = defaultControlParameterSettings.map(
      (item: IControlParameterSettingsRequest, index: number) => ({
        defaultSettingId: item.id,
        isVisibleInTable: currentFeedPlan.controlParameters[index].isVisibleInTable,
        isVisibleInGraph: currentFeedPlan.controlParameters[index].isVisibleInGraph,
        isVisibleInPrint: item.isDefaultVisibleInPrint,
        name: item.name,
      })
    );
    return updatedControlParameterSettings;
  }

  const updateControlParameterItemInCurrentFeedPlan = async (
    updatedState: IFeedPlanDropdownOptionsState[] | undefined,
    updatedControlParameterItems: IControlParameterSettingsRequest[] | undefined,
    operation: string,
    updatedGraphViaTableState: IFeedPlanDropdownOptionsState[] | undefined
  ) => {
    if (updatedState) {
      if (operation === "controlParameterSetting") {
        updatedControlParameterItems?.forEach((item, index) => {
          item.isVisibleInTable = updatedState[index].visible;
          if (updatedGraphViaTableState) {
            item.isVisibleInGraph = updatedGraphViaTableState[index].visible;
          }
        });
      } else {
        updatedControlParameterItems?.forEach((item, index) => {
          if (item.isVisibleInGraph !== updatedState[index].visible) {
            item.isVisibleInGraph = updatedState[index].visible;
          }
        });
      }
    }
    return updatedControlParameterItems;
  };

  const updateFeedDietItemInCurrentFeedPlan = async (
    currentFeedPlan: ICustomerFeedPlan,
    updatedFeedDietItems: IFeedDiet[],
    persist: boolean = false
  ) => {
    if (!currentFeedPlan) {
      return;
    }

    let feedDietItemsCopy = [...currentFeedPlan.feedDietItems];
    const updatedFeedDietItemsCopy = [...updatedFeedDietItems];

    updatedFeedDietItemsCopy.forEach((feedDietItem) => {
      const restructuredUpdatedFeedDietItem = {} as IFeedDietItem;
      const yieldLevelValues = {} as IYieldLevelValues;
      let feedIndexer: number = 1;
      Object.entries(feedDietItem).forEach(([key, value]) => {
        // Check if key represent a numeric index or specific key for milkCow
        if (parseInt(key) || key === "closeUp3-0w" || key === "farOff8-3w") {
          let feedIndex: string;

          if (currentFeedPlan.animalType.name !== "milkCow") {
            feedIndex = `${feedIndexer}.00`;
          } else {
            feedIndex = key;
          };

          yieldLevelValues[feedIndex] = value;
          feedIndexer += 1;
        } else if (key !== "feedName") {
          restructuredUpdatedFeedDietItem[key] = value;
        }
        restructuredUpdatedFeedDietItem.yieldLevelValues = yieldLevelValues;
        feedDietItemsCopy = feedDietItemsCopy.map((dietItem) =>
        dietItem.customerFeedId === restructuredUpdatedFeedDietItem.customerFeedId
            ? restructuredUpdatedFeedDietItem
            : dietItem
        );
      });
    });

    const url = `${BASE_FEED_PLAN_URL}/${currentFeedPlan.id}/feeds?persist=${persist}`;
    const body = { feedDietItems: feedDietItemsCopy };

    const options: IPutOptions = {
      method: "PUT",
      body: JSON.stringify(body),
    };

    const result = await put(url, options);
    const updatedFeedPlan: ICustomerFeedPlan = await result.json();

    return updatedFeedPlan;
  };

  const saveUpdatedFeedDietItemsInCurrentFeedPlan =
    async(currentFeedPlan: ICustomerFeedPlan, persist: boolean = true) => {

    const url = `${BASE_FEED_PLAN_URL}/${currentFeedPlan.id}/feeds?persist=${persist}`;
    const body = { feedDietItems: currentFeedPlan.feedDietItems };

    const options: IPutOptions = {
      method: "PUT",
      body: JSON.stringify(body),
    };

    const result = await put(url, options);
    const updatedFeedPlan: ICustomerFeedPlan = await result.json();
    return updatedFeedPlan;
  }

  const saveUpdatedControlParametersItemsInCurrentFeedPlan = useCallback(
    async (
      currentFeedPlan: ICustomerFeedPlan,
      unsavedControlParameterSettingItems: IControlParameterSettingsRequest[] | undefined
    ) => {
      const updatedCurrentFeedPlan: ICustomerFeedPlan = currentFeedPlan;
      const url = `${BASE_FEED_PLAN_URL}/${currentFeedPlan.id}/controlparametersettings`;
      const body = { controlParameters: unsavedControlParameterSettingItems };
      const options: IPutOptions = {
        method: "PUT",
        body: JSON.stringify(body),
      };
      await put(url, options);

      if (unsavedControlParameterSettingItems) {
        updatedCurrentFeedPlan.controlParameters.forEach(
          (item: IControlParameter, index: number) => {
            item.isVisibleInGraph = unsavedControlParameterSettingItems[index].isVisibleInGraph;
            item.isVisibleInTable = unsavedControlParameterSettingItems[index].isVisibleInTable;
          }
        );
      }
      return updatedCurrentFeedPlan;
    },
    []
  );

  const saveFeedMixToDatabase = async ({
    customerId,
    feedName,
    feedNumber,
  }: IFeedMixCreateRequest) => {
    const url = `${BASE_FEED_MIX_URL}`;
    const body = { customerId, feedName, feedNumber };
    const options: IPostOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };

    let result = await post(url, options);

    if (result === 409) {
      await isConfirmed(`${t("dublicateFeedMixError")} ${feedNumber} ${feedName}`, "alert");
      return;
    }

    if (!result.ok) {
      result = await result.json();
      const { errorMessage } = result;
      throw new Error(errorMessage);
    } else {
      result = await result.json();
      return result;
    }
  };

  const updateFeedMixItemsInDatabase = async (
    feedMixItems: IFeedMixUpdateRequest,
    customerFeedMixId: number | undefined,
    persist: boolean
  ) => {
    if (!customerFeedMixId) {
      return;
    }

    const url = `${BASE_FEED_MIX_URL}/${customerFeedMixId}/feeds?persist=${persist}`;
    const body = feedMixItems;
    const options: IPutOptions = {
      method: "PUT",
      body: JSON.stringify(body),
    };

    let result: IFeedMix = await put(url, options);
    if (!result.ok) {
      result = await result.json();
      const { errorMessage } = result;
      throw new Error(errorMessage);
    } else {
      result = await result.json();
      return result;
    }
  };

  const deleteFeedMixFromDatabase = async (customerFeedMixId: number) => {
    const url = `${BASE_FEED_MIX_URL}/${customerFeedMixId}`;
    const options: IDeleteOptions = {
      method: "DELETE",
    };
    let result = await deleteRequest(url, options);
    if (!result.ok) {
      result = await result.json();
      const { errorMessage } = result;
      throw new Error(errorMessage);
    } else {
      return result;
    }
  };

  const copyFeedMixToDatabase = async (
    customerFeedMixId: number,
    newName: string,
    feedNumber: number | string
  ) => {
    const url = `${BASE_FEED_MIX_URL}/${customerFeedMixId}/copy`;
    const body = { newName, feedNumber };
    const requestOptions: IPostOptions = {
      method: "POST",
      body: JSON.stringify(body),
    };

    let result = await post(url, requestOptions);
    if (!result.ok) {
      result = await result.json();
      const { errorMessage } = result;
      throw new Error(errorMessage);
    } else {
      result = await result.json();
      return result;
    }
  };

  const downloadFeedMixPDF = async (
    customerFeedMixId: number,
    settings: IFeedMixPrint,
    feedMixName: string
  ) => {
    const { language } = i18n;

    let parsedLang: string;
    if (language.includes("sv")) {
      parsedLang = "sv";
    } else {
      parsedLang = "en";
    }

    const url = `${BASE_FEED_MIX_URL}/${customerFeedMixId}/pdf?culture=${parsedLang}`;
    const requestOptions: IPostOptions = {
      method: "POST",
      body: JSON.stringify(settings),
    };

    return createPdfFile(url, requestOptions, feedMixName);
  };

  return {
    changeControlSettings,
    checkControlParameterConditionsForTable,
    checkControlParameterConditionsForGraph,
    copyFeedPlanToDatabase,
    copyFeedMixToDatabase,
    createPdfFile,
    deleteFeedPlanFromDatabase,
    deleteFeedMixFromDatabase,
    downloadFeedMixPDF,
    downloadFeedPlanPDF,
    editFeedsInCurrentFeedPlan,
    editFeedPlanInDatabase,
    getAnimalTypesFromDatabase,
    getDefaultControlParameterSettings,
    getFeedPlanVisibleInTableOptions,
    getFeedPlanVisibleInGraphOptions,
    getYieldLevelsFromDatabase,
    saveEditedFeedsToDB,
    saveFeedMixToDatabase,
    saveFeedPlanToDatabase,
    saveUpdatedControlParametersItemsInCurrentFeedPlan,
    saveUpdatedFeedDietItemsInCurrentFeedPlan,
    updateControlParameterItemInCurrentFeedPlan,
    updateFeedDietItemInCurrentFeedPlan,
    updateFeedMixItemsInDatabase,
  };
}

export default useSaveFeedRequest;
