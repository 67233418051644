import { useCallback, useContext, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useErrorHandler } from "react-error-boundary";

import PreferredYieldSettings from "pages/feed-plan-calculation/parts/PreferredYieldSettings";
import { CustomerContext } from "pages/choose-customer/context/CustomerProvider";
import { setReadOnlyMode, setCurrentFeedPlan } from "pages/choose-customer/context/customerActions";

import useSaveFeedRequest from "hooks/useSaveFeedRequest";
import useConversion from "hooks/useConversion";
import useConfirm from "hooks/useConfirm";

import LMButton from "components/lm-button/LMButton";

import ICustomerFeedPlan, { IYieldLevelValues } from "interfaces/ICustomerFeedPlan";
import ICustomerFeedPlanRequest from "interfaces/ICustomerFeedPlanRequest";

interface FeedPlanSettingsParams {
  feedPlanId: string;
}

interface FeedPlanSettingsProps {
  feedPlanToEdit?: ICustomerFeedPlan;
}

interface IAnimalDataTypes {
  id: string;
  name: string;
}

const FeedPlanSettings = ({ feedPlanToEdit }: FeedPlanSettingsProps): JSX.Element => {
  const [animalTypeData, setAnimalTypeData] = useState<IAnimalDataTypes[]>([]);
  const [feedPlanName, setFeedPlanName] = useState(feedPlanToEdit?.name || "");
  const [animalWeight, setAnimalWeight] = useState(feedPlanToEdit?.animalWeight || "");
  const [animalTypeName, setAnimalTypeName] = useState(
    feedPlanToEdit?.animalType.name || "milkCow"
  );
  const [startLevel, setStartLevel] = useState(feedPlanToEdit?.yieldLevelStart || "");
  const [stepLevel, setStepLevel] = useState(feedPlanToEdit?.yieldLevelStep || "0");
  const [endLevel, setEndLevel] = useState(feedPlanToEdit?.yieldLevelStop || "");
  const { t } = useTranslation();
  const { t: tWithKeyPrefix } = useTranslation("", {
    keyPrefix: "createOrEditFeedPlanParameters",
  });
  const { removeAllNonNumbersExceptPeriod } = useConversion();
  const {
    saveFeedPlanToDatabase,
    editFeedPlanInDatabase,
    editFeedsInCurrentFeedPlan,
    getAnimalTypesFromDatabase,
  } = useSaveFeedRequest();
  const { feedPlanId }: FeedPlanSettingsParams = useParams();
  const history = useHistory();
  const { goBack } = useHistory();
  const handleError = useErrorHandler();
  const { isConfirmed } = useConfirm();

  const {
    customerState: { currentCustomer, currentFeedPlan },
    customerDispatch,
  } = useContext(CustomerContext);

  const yieldLevels = { start: startLevel, step: stepLevel, end: endLevel };

  const handleGetAnimalTypeOptions = async () => {
    const animalTypeResult = await getAnimalTypesFromDatabase();
    setAnimalTypeData(animalTypeResult.animalTypes);
  };

  useEffect(() => {
    handleGetAnimalTypeOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentCustomer) {
      isConfirmed(t("mustChooseCustomer"), "alert");
      history.replace("/choose-customer");
    }
  }, [currentCustomer, history, t, isConfirmed]);

  const handleOnFeedPlanNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFeedPlanName(e.target.value);
  };

  const handleOnCowTypeChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setAnimalTypeName(e.target.value);
  };

  const handleOnAnimalWeightChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAnimalWeight(removeAllNonNumbersExceptPeriod(e.target.value));
  };

  const renderOptions = useMemo(
    (): JSX.Element[] =>
      animalTypeData
        .filter((option) => option.name !== "sucklerCow")
        .map((option) => (
          <option key={option.id} value={option.name}>
            {t(option.name)}
          </option>
        )),
    [t, animalTypeData]
  );

  const editCurrentYieldLevelValues = async (requestObject: ICustomerFeedPlanRequest) => {
    const  [yieldLevelStart, yieldLevelStep, yieldLevelStop] =
      [Number(requestObject.yieldLevelStart),
        Number(requestObject.yieldLevelSte),
        Number(requestObject.yieldLevelStop)];
    let feedDietItems = currentFeedPlan?.feedDietItems;
    const currentYieldLevelStep = currentFeedPlan?.yieldLevelStep;

    if (!feedDietItems || !yieldLevelStart || !yieldLevelStep || !yieldLevelStop) {
      return;
    }

    const copyOfYieldLevelValues = feedDietItems.map(({ yieldLevelValues }) => yieldLevelValues);

    if (currentYieldLevelStep !== yieldLevelStep) {
      feedDietItems = feedDietItems.map((feedDiet) => {
        const updatedValues: IYieldLevelValues = {};
        for (
          let i = yieldLevelStart;
          i <= yieldLevelStop;
          i += yieldLevelStep
        ) {
          const formattedValue = i.toFixed(2);
          if (!updatedValues[formattedValue]) {
            updatedValues[formattedValue] = 0;
          }
        }

        return { ...feedDiet, yieldLevelValues: updatedValues };
      });
    } else {
      copyOfYieldLevelValues.forEach((level) => {
        const highestCurrentLevel = parseInt(Object.keys(level)[Object.keys(level).length - 1]);
        const lowestCurrentLevel = parseInt(Object.keys(level)[0]);
        const updatedValues = level;

        // Check if we need to add up
        if (highestCurrentLevel < yieldLevelStop) {
          for (
            let i = highestCurrentLevel;
            i <= yieldLevelStop;
            i += yieldLevelStep
          ) {
            const formattedValue = i.toFixed(2);
            updatedValues[formattedValue] = 0;
          }
        }

        // Check if we need to add down
        if (lowestCurrentLevel > yieldLevelStart) {
          for (
            let i = lowestCurrentLevel;
            i >= yieldLevelStart;
            i -= yieldLevelStep
          ) {
            const formattedValue = i.toFixed(2);
            updatedValues[formattedValue] = 0;
          }
        }

        // Check if we need to remove up
        if (highestCurrentLevel > yieldLevelStop) {
          for (
            let i = yieldLevelStop + yieldLevelStep;
            i <= highestCurrentLevel;
            i += yieldLevelStep
          ) {
            const formattedValue = i.toFixed(2);
            delete updatedValues[formattedValue];
          }
        }

        // Check if we need to remove down
        if (lowestCurrentLevel < yieldLevelStart) {
          for (
            let i = yieldLevelStart - yieldLevelStep;
            i >= lowestCurrentLevel;
            i -= yieldLevelStep
          ) {
            const formattedValue = i.toFixed(2);
            delete updatedValues[formattedValue];
          }
        }

        return updatedValues;
      });
    }

    try {
      await editFeedsInCurrentFeedPlan(feedPlanId, feedDietItems!);
    } catch (error) {
      handleError(new Error(t("fetchingError")));
    }
  };

  const editCurrentFeedPlan = useCallback(
    async (requestObject: ICustomerFeedPlanRequest) => {
      await editFeedPlanInDatabase(feedPlanId, requestObject);
      await editCurrentYieldLevelValues(requestObject);
      customerDispatch(setReadOnlyMode(false));
      history.push(`/feed-plan-calculation/${feedPlanId}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, editFeedPlanInDatabase]
  );

  const saveNewFeedPlan = useCallback(
    async (requestObject: ICustomerFeedPlanRequest) => {
      const result = await saveFeedPlanToDatabase(requestObject);
      if (result) {
        setCurrentFeedPlan(result);
        customerDispatch(setReadOnlyMode(false));
        history.push(`/feed-plan-calculation/${result?.id}`);
      }
    },
    [history, saveFeedPlanToDatabase, customerDispatch]
  );

  const getAnimalTypeYieldStop = (animalTypeId: number) => {
    let yieldLevelStop: number = 0;
    if (animalTypeId === 3) {
      yieldLevelStop = 6;
    } else if (animalTypeId === 4) {
      yieldLevelStop = 7;
    } else {
      yieldLevelStop = 2;
    }
    return yieldLevelStop;
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();

      let animalTypeId: number = 0;
      if (animalTypeData) {
        animalTypeData.forEach((item) => {
          if (item.name === animalTypeName) {
            animalTypeId = parseInt(item.id);
          }
        });
      }

      if (
        !feedPlanName ||
        !animalWeight ||
        !animalTypeName ||
        !startLevel ||
        !stepLevel ||
        !endLevel
      ) {
        if (startLevel !== endLevel && stepLevel !== 0) {
          return isConfirmed(t("enterAllFields"), "alert");
        }
      }

      const customerId = currentCustomer?.customerId || "";
      let requestObject: ICustomerFeedPlanRequest = {
        customerId,
        name: feedPlanName,
        animalWeight,
        yieldLevelStart: 1,
        yieldLevelStop: 1,
        yieldLevelStep: 1,
        animalTypeId,
      };
      if (animalTypeName !== "milkCow") {
        requestObject = {
          customerId,
          name: feedPlanName,
          animalWeight: animalWeight || 0,
          yieldLevelStart: 1,
          yieldLevelStop: getAnimalTypeYieldStop(animalTypeId),
          yieldLevelStep: 1,
          animalTypeId,
        };
      } else {
        requestObject = {
          customerId,
          name: feedPlanName,
          animalWeight,
          yieldLevelStart: startLevel,
          yieldLevelStop: endLevel,
          yieldLevelStep: stepLevel,
          animalTypeId,
        };
      }

      if (feedPlanId) {
        await editCurrentFeedPlan(requestObject);
      } else {
        await saveNewFeedPlan(requestObject);
      }
    },
    [
      animalTypeData,
      animalTypeName,
      animalWeight,
      currentCustomer,
      editCurrentFeedPlan,
      endLevel,
      feedPlanId,
      feedPlanName,
      saveNewFeedPlan,
      startLevel,
      stepLevel,
      t,
      isConfirmed,
    ]
  );

  const handleOnCancel = useCallback(() => {
    if (feedPlanId) {
      history.push(`/feed-plan-calculation/${feedPlanId}`);
    } else {
      goBack();
    }
  }, [feedPlanId, goBack, history]);

  const onYieldSettingsUpdate = useCallback((updatedYieldSettings) => {
    // Room for logic here when the preferredYieldSettings updates.
    const { start, step, end } = updatedYieldSettings;

    if (start || step || end) {
      setStartLevel(start);
      setStepLevel(step);
      setEndLevel(end);
    }
  }, []);

  const returnYieldSettingsDataForAnimalTypes = (): JSX.Element => {
    let yieldSettingsData: JSX.Element | null = null;

    if (animalTypeName === "milkCow") {
      yieldSettingsData = (
        <div className="animal-data">
          <p>{tWithKeyPrefix("animalData")}</p>
          <input
            onChange={handleOnAnimalWeightChange}
            placeholder={tWithKeyPrefix("enterAnimalWeight")}
            type="text"
            value={animalWeight}
          />
          <PreferredYieldSettings
            onYieldSettingsUpdate={onYieldSettingsUpdate}
            yieldValues={yieldLevels}
          />
        </div>
      );
    } else if (animalTypeName === "dryCow") {
      yieldSettingsData = (
        <div className="animal-data">
          <p>{tWithKeyPrefix("animalData")}</p>
          <input
            onChange={handleOnAnimalWeightChange}
            placeholder={tWithKeyPrefix("enterAnimalWeight")}
            type="text"
            value={animalWeight}
          />
        </div>
      );
    }
    
    return yieldSettingsData || <div className="hidden">No data</div>;
  };

  return (
    <form className="feed-plan-settings" onSubmit={handleOnSubmit}>
      <div className="wrapper">
        <div className="plan-config">
          <p>{tWithKeyPrefix("planConfig")}</p>
          <input
            onChange={handleOnFeedPlanNameChange}
            placeholder={tWithKeyPrefix("enterName")}
            type="text"
            value={feedPlanName}
          />
          {animalTypeData && animalTypeData.length && (
            <select onChange={handleOnCowTypeChange} value={animalTypeName}>
              {renderOptions}
            </select>
          )}
        </div>
        {returnYieldSettingsDataForAnimalTypes()}
      </div>
      <div className="button-wrapper">
        {feedPlanId ? (
          <>
            <LMButton text={t("save")} type="submit" className="saveFeedButton" />
            <LMButton
              text={t("cancel")}
              type="button"
              click={handleOnCancel}
              className="cancelFeedButton"
            />
          </>
        ) : (
          <>
            <LMButton text={t("createNew")} type="submit" className="createFeedButton" />
            <LMButton
              text={t("cancel")}
              type="button"
              click={handleOnCancel}
              className="cancelFeedButton"
            />
          </>
        )}
      </div>
    </form>
  );
};

export default withAuthenticationRequired(FeedPlanSettings);
